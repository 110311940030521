import React from "react"
import styled from "styled-components"
import eleanorImg from "../../../images/landing/testimonials-1.png"
import ayanaImg from "../../../images/landing/testimonials-2.png"
import quoteImg from "../../../images/landing/quote-mark.svg"

const TESTIMONIAL_SLIIDES = [
  {
    img: eleanorImg,
    testimonial:
      "When I paged through my wedding photo book for the first time, I teared-up at how beautiful it was. It’s exactly what I would have designed if I could design.",
    name: "Eleanor",
    size: "150px",
  },
  {
    img: ayanaImg,
    testimonial:
      "I got a book designed for my parent’s 40th wedding anniversary. I couldn’t have given them a more precious gift. The quality was impeccable!",
    name: "Ayana",
    size: "135px",
  },
]

const Slide = () => {
  const renderTestimonialSlides = () => {
    return TESTIMONIAL_SLIIDES.map((item, i) => {
      return (
        <SwiperSlide key={i} className="swiper-slide">
          <div className="flex">
            <div className="img">
              <img
                src={item.img}
                style={{ width: `${item.size}` }}
                alt="Build My Book"
              />
            </div>
            <div className="text">
              <h5>{item.testimonial}</h5>
              <div className="name">
                <img src={quoteImg} alt="Build My Book" />
                <p>{item.name}</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
      )
    })
  }
  return <>{renderTestimonialSlides()}</>
}

const SwiperSlide = styled.div`
  position: relative;
  .flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .text {
    padding: 30px 20px;
    border-radius: 20px;
    background: ${props => props.theme.color.lightGrey};
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(246, 248, 247, 1) 20%
    );
    .name {
      display: flex;
      align-items: center;
      p {
        font-size: 0.8rem;
        font-family: ${props => props.theme.font.galanoSemi};
      }
      img {
        width: 30px;
        margin-right: 10px;
      }
    }
  }
  h5 {
    margin-bottom: 15px;
    font-size: 1rem;
  }
  @media (min-width: 768px) {
    .flex {
      flex-direction: row;
      height: 180px;
    }
  }
`
export default Slide
