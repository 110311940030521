import React, { useRef, useState, useEffect } from "react"
import styled from "styled-components"
import { Swiper } from "swiper/dist/js/swiper.esm"
import Slide from "./Slide"
import arrowRightImg from "../../../images/landing/arrow-right-light.svg"
// import trustLogo from "../../../images/landing/trust-pilot-logo.png"

const Testimonials = () => {
  const swiperElement = useRef(null)
  const swiper = useRef(null)

  const [activeSlide, setActiveSlide] = useState(0)

  useEffect(() => {
    swiper.current = new Swiper(swiperElement.current, {
      speed: 500,
      slidesPerView: 1,
      initialSlide: activeSlide,
      loop: true,
      breakpointsInverse: true,
      breakpoints: {
        768: {
          spaceBetween: 50,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 100,
        },
      },
      on: {
        transitionEnd: () => {
          if (swiper.current) {
            setActiveSlide(swiper.current.activeIndex)
          }
        },
      },
    })
  }, []) // eslint-disable-line

  const slideNext = () => {
    if (swiper.current) {
      swiper.current.slideNext()
    }
  }

  return (
    <Container>
      <Header>
        <div>
          <h3>Our customers love us</h3>
          <p className="subheading">and we love them too!</p>
        </div>
        {/* <img src={trustLogo} alt="Build My Book" /> */}
      </Header>
      <SwiperContainer>
        <div className="swiper-container" ref={swiperElement}>
          <div className="swiper-wrapper">
            <Slide />
          </div>
        </div>
        <img
          className="next-arrow"
          src={arrowRightImg}
          onClick={slideNext}
          alt="Build My Book"
        />
      </SwiperContainer>
    </Container>
  )
}

const Container = styled.div`
  padding: ${props => props.theme.section.paddingTwo};
`
const SwiperContainer = styled.div`
  padding: 100px 5% 0 5%;
  position: relative;
  .next-arrow {
    max-width: 40px;
    position: absolute;
    right: 5%;
    bottom: -10%;
    cursor: pointer;
  }
  @media (min-width: 1024px) {
    .next-arrow {
      right: -20px;
      bottom: 80px;
    }
  }
`
const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    font-size: 1.4rem;
    color: ${props => props.theme.color.grey};
  }
  img {
    max-width: 100px;
  }
  .subheading {
    font-family: ${props => props.theme.font.saturdayRock};
    font-size: 1.2rem;
    transform: rotate(-2deg);
    position: absolute;
  }
  @media (min-width: 768px) {
    h3 {
      font-size: 1.8rem;
    }
    img {
      max-width: 200px;
    }
    .subheading {
      left: 150px;
      font-size: 1.6rem;
    }
  }
`
export default Testimonials
