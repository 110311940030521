/**
 * @flow
 */
import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"
import uuidv1 from "uuid/v1"

import { ProductSelect } from "../../products"
import {
  useCheckoutCreate,
  useCheckoutLineItemsReplace,
} from "../../products/utils"

import { useCustomerCheckout } from "../../checkout/utils"

import {
  setCheckoutId,
  setCheckoutWebUrl,
  setSelectedVariant,
  setProjectId,
  clearCheckout,
} from "../../../state/actions"

const BrochurePage = () => {
  const createCheckout = useCheckoutCreate()
  const replaceLineItems = useCheckoutLineItemsReplace()
  const dispatch = useDispatch()
  const checkoutId = useSelector(state => state.checkout.id)

  const [currentCheckout, isCurrentCheckoutLoading] = useCustomerCheckout(
    checkoutId
  )

  useEffect(() => {
    if (
      checkoutId &&
      !isCurrentCheckoutLoading &&
      !_.isEmpty(currentCheckout)
    ) {
      if (currentCheckout.completedAt !== null) {
        dispatch(clearCheckout())
      }
    }
  }, [checkoutId, currentCheckout, dispatch, isCurrentCheckoutLoading])

  const onProductSelect = async (productId, variant) => {
    if (checkoutId) {
      // We have a checkout already, replace the existing line items
      // eslint-disable-next-line no-unused-vars
      const [response, errors] = await replaceLineItems(
        [
          {
            variantId: variant.id,
            quantity: 1,
          },
        ],
        checkoutId
      )

      if (_.isEmpty(errors)) {
        dispatch(setSelectedVariant(variant))
        navigate("/account")
      }
    } else {
      // We don't have a checkout yet, create one
      const [response, errors] = await createCheckout(variant.id)
      const newProjectId = uuidv1()
      dispatch(setProjectId(newProjectId))
      if (_.isEmpty(errors)) {
        dispatch(setCheckoutId(response.data.checkoutCreate.checkout.id))
        dispatch(
          setCheckoutWebUrl(response.data.checkoutCreate.checkout.webUrl)
        )
        dispatch(setSelectedVariant(variant))
        navigate("/account")
      }
    }
  }

  return <ProductSelect onSelect={onProductSelect}></ProductSelect>
}

export default BrochurePage
