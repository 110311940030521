import React from "react"
import styled from "styled-components"

import Navbar from "../Navbar"
import Landing from "./Landing"
import HowItWorks from "./HowItWorks"
import Attributes from "./Attributes"
import Product from "./Product"
import Testimonials from "./Testimonials"
import Footer from "../Footer"

const HomePage = () => (
  <>
    <Navbar />
    <ComponentsContainer>
      <Landing />
      <HowItWorks />
      <Attributes />
      <Product />
      <Testimonials />
    </ComponentsContainer>
    <Footer />
  </>
)

const ComponentsContainer = styled.div`
  margin-top: 75px;
  @media (min-width: 768px) {
    margin-top: 140px;
  }
`
export default HomePage
