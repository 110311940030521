import React from "react"
import styled from "styled-components"
import Step from "./Step"
import step1Img from "../../../images/landing/step-1.svg"
import step2Img from "../../../images/landing/step-2.svg"
import step3Img from "../../../images/landing/step-3.svg"

import uploadIcon from "../../../images/landing/upload-icon.svg"
import toDoIcon from "../../../images/landing/things-to-do-icon.svg"
import lookIcon from "../../../images/landing/have-a-look-icon.svg"
import underlineImg from "../../../images/landing/underline-scribble.svg"

const STEP_ONE = {
  step: "1",
  heading: "upload your photos",
  text: "All you need to do is upload your photos to our website.",
  links: [
    {
      img: uploadIcon,
      label: "more about photo uploads",
      url: "/faq",
      panel: "panel1",
    },
  ],
}

const STEP_TWO = {
  step: "2",
  heading: "sit back and relax",
  text:
    "We’ve got it from here. One of our expert designers will be assigned to your project and transform your memories into a precious photo book.",
  links: [
    {
      img: toDoIcon,
      label: "things to do while you wait",
      url: "/things-to-do-while-you-wait",
      panel: "panel1",
    },
  ],
}

const STEP_THREE = {
  step: "3",
  heading: "enjoy your photo book",
  text:
    "Within 10 working days of receiving your photos, we’ll deliver your photo book to your door. Now you can enjoy your memories over and over again.",
  links: [
    {
      img: lookIcon,
      label: "have a look at some examples",
      url: "/examples",
    },
  ],
}

const HowItWorks = () => (
  <Container id="how-it-works">
    <Header>
      <h2>Let us design your next photo book</h2>
      <p className="subheading">
        Designed, printed and delivered within <span>10 working days</span>
      </p>
    </Header>
    <StepContainer className="padded-left">
      <div className="step">
        <Step step={STEP_ONE} />
      </div>
      <div className="image">
        <img src={step1Img} alt="Build My Book" />
      </div>
    </StepContainer>
    <StepContainer className="padded-right spacing-top">
      <div className="image">
        <img src={step2Img} alt="Build My Book" />
      </div>
      <div className="step">
        <Step step={STEP_TWO} />
      </div>
    </StepContainer>
    <StepContainer className="padded-left spacing-top">
      <div className="step">
        <Step step={STEP_THREE} />
      </div>
      <div className="image">
        <img src={step3Img} alt="Build My Book" />
      </div>
    </StepContainer>
  </Container>
)

const Container = styled.div`
  padding: 50px 5%;
  @media (min-width: 768px) {
    padding: 100px 0;
  }
`
const Header = styled.div`
  text-align: center;
  h2 {
    font-size: 1.5rem;
    font-family: ${props => props.theme.font.galanoBold};
    color: ${props => props.theme.color.grey};
  }
  .subheading {
    font-family: ${props => props.theme.font.saturdayRock};
    font-size: 1.2rem;
    span {
      background-image: url(${underlineImg});
      background-repeat: no-repeat;
      background-position: 100% 80%;
    }
  }
  @media (min-width: 768px) {
    padding-bottom: 50px;
    h2 {
      font-size: 2.5rem;
    }
    .subheading {
      position: relative;
      left: 100px;
      transform: rotate(-2deg);
      display: inline-block;
      font-size: 1.6rem;
      top: 5px;
    }
  }
  @media (min-width: 1024px) {
    .subheading {
      left: 200px;
    }
  }
`

const StepContainer = styled.div`
  padding-top: 50px;
  .image {
    display: none;
  }
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    .step {
      width: 50%;
    }
    .image {
      display: block;
      width: 50%;
    }
  }
  @media (min-width: 1024px) {
    &.spacing-top {
      margin-top: 50px;
    }
    &.padded-left {
      padding-left: 10%;
    }
    &.padded-right {
      padding-right: 10%;
    }
    .step {
      width: 35vw;
    }
    .image {
      width: 65vw;
    }
  }
`
export default HowItWorks
