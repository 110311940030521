import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const AttributeBlock = props => {
  const { img, text, header } = props
  return (
    <Attribute>
      <img src={img} alt="Build My Book" />
      <h1>{header}</h1>
      <p>{text}</p>
    </Attribute>
  )
}
const Attribute = styled.div`
  display: block;
  padding: 0 5%;
  h1 {
    text-transform: uppercase;
    font-family: ${props => props.theme.font.galanoBold};
    font-size: 1rem;
  }
  img {
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
  }
  p {
    font-size: 0.8rem;
    color: ${props => props.theme.color.dark};
  }
  @media (min-width: 1024px) {
    h1 {
      font-size: 1.2rem;
    }
    p {
      font-size: 1rem;
    }
  }
`

AttributeBlock.propTypes = {
  img: PropTypes.string,
  text: PropTypes.string,
  header: PropTypes.string,
}
export default AttributeBlock
