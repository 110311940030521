import React from "react"
import styled from "styled-components"
import arrowImg from "../../../images/landing/arrow-right-dark.svg"
import { Link } from "gatsby"

const renderSteps = item => {
  const { step, heading, text, links } = item.step
  return (
    <Container>
      <p className="step">Step {step}</p>
      <p className="heading">{heading}</p>
      <p>{text}</p>
      {links.map((link, i) => {
        const { label, img, url, panel } = link
        return (
          <NavLink key={i} to={url} className="links" state={{ panel }}>
            <img src={img} alt="Build My Book" />
            <p>{label}</p>
            <img src={arrowImg} className="arrow" alt="Build My Book" />
          </NavLink>
        )
      })}
    </Container>
  )
}
const Step = props => {
  return <>{renderSteps(props)}</>
}

const Container = styled.div`
  .step {
    font-size: 2rem;
    letter-spacing: 5px;
    font-family: ${props => props.theme.font.saturdayRock};
  }
  .heading {
    font-size: 1.2rem;
    font-family: ${props => props.theme.font.galanoBold};
    text-transform: uppercase;
    padding-top: 10px;
  }
  p {
    font-size: 0.8rem;
  }
  @media (min-width: 768px) {
    padding: 0 25px;
    .step {
      font-size: 2.4rem;
    }
    .heading {
      font-size: 1.4rem;
    }
  }
  @media (min-width: 1100px) {
    padding: 0 50px;
    p {
      font-size: 1rem;
    }
  }
`

const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  margin-top: 20px;
  p,
  img {
    transition: 0.25s ease;
  }
  p {
    margin: 0 5px 0 10px;
    font-family: ${props => props.theme.font.galanoSemi};
  }
  img {
    width: 30px;
    &.arrow {
      width: 15px;
    }
  }
  &:hover {
    p {
      opacity: 0.6;
    }
    img {
      &.arrow {
        margin-left: 5px;
      }
    }
  }
`

export default Step
