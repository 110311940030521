import React from "react"
import styled from "styled-components"
import AttributeBlock from "./AttributeBlock"
import perfectFitImg from "../../../images/landing/perfect-fit-icon.svg"
import highQualityImg from "../../../images/landing/highest-quality-icon.svg"
import makeItYoursImg from "../../../images/landing/make-it your-icon.svg"
import backgroundImg from "../../../images/landing/mid-section-texture.png"

const Attributes = () => {
  return (
    <Container>
      <Header>
        <h1>
          We believe that your best memories <br />
          deserve the best quality.
        </h1>
      </Header>
      <AttributeContainer>
        <AttributeBlock
          img={perfectFitImg}
          header="PROFESSIONALLY PRINTED"
          text="Every photo book is printed by our state-of-the-art technology for a crisp, vibrant finish."
        />
        <AttributeBlock
          img={highQualityImg}
          header="IMPORTED PAPER"
          text="Your photos will be printed on only the finest papers, sourced from around the world."
        />
        <AttributeBlock
          img={makeItYoursImg}
          header="BOUND TO LAST"
          text="Our photo books are pur bound with the best hot glue in the business, ensuring they withstand countless flip-throughs."
        />
      </AttributeContainer>
      <p className="sizes">
        We’re so confident of our quality that we offer a money-back guarantee.
      </p>
    </Container>
  )
}

const Container = styled.div`
  padding: ${props => props.theme.section.paddingTwo};
  background-image: url(${backgroundImg});
  background-size: cover;
  background-position: center;
  text-align: center;
  .sizes {
    margin: 50px 0;
    font-family: ${props => props.theme.font.galanoSemi};
  }
  @media (min-width: 768px) {
    .sizes {
      margin: 75px 0;
    }
  }
  @media (min-width: 1024px) {
    background-size: contain;
  }
`
const Header = styled.div`
  padding-bottom: 75px;
  h1 {
    font-family: ${props => props.theme.font.galanoSemi};
    color: ${props => props.theme.color.grey};
    font-size: 1.2rem;
  }
  @media (min-width: 768px) {
    h1 {
      font-size: 1.4rem;
    }
  }
  @media (min-width: 1024px) {
    h1 {
      font-size: 1.7rem;
    }
  }
`
const AttributeContainer = styled.div`
  display: grid;
  grid-gap: 50px;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (min-width: 1024px) {
    padding: 0 5%;
  }
`

export default Attributes
