import React from "react"
import { Link } from "react-scroll"
import styled from "styled-components"
import LandingBanner from "../../../images/landing/landing-background.jpg"
import arrowImg from "../../../images/landing/yellow-arrow-down.svg"

const Landing = () => {
  return (
    <Container>
      <Background>
        <div>
          <p className="landingHeader">Your story</p>
          <h1>Designed by us</h1>
          <p>Told by you</p>
        </div>
      </Background>
      <Link
        to="how-it-works"
        spy={true}
        smooth={true}
        offset={-100}
        duration={500}
      >
        <img src={arrowImg} alt="Build My Book" />
      </Link>
    </Container>
  )
}

const Container = styled.div`
  padding: ${props => props.theme.section.paddingOne};
  img {
    display: block;
    margin: 10px auto 0 auto;
    width: 8px;
    cursor: pointer;
    position: relative;
    top: 0;
    transition: top 0.25s ease;
    &:hover {
      top: 5px;
    }
  }
`

const Background = styled.div`
  min-height: 40vh;
  background-image: url(${LandingBanner});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  div {
    text-align: right;
    padding: 25px 25px 0 0;
    h1 {
      font-size: 1.5rem;
      font-family: ${props => props.theme.font.galanoBold};
      color: ${props => props.theme.color.grey};
    }
    p {
      margin-top: 15px;
      font-size: 1.5rem;
      font-family: ${props => props.theme.font.saturdayRock};
      color: ${props => props.theme.color.grey};
    }
  }
  @media (min-width: 768px) {
    min-height: 100vh;
    background-size: cover;
    background-position: 20% 100%;
    div {
      padding: 75px 50px 0 0;
      h1 {
        font-size: 3.8rem;
        transform: translateX(-90px);
      }
      p {
        font-size: 4rem;
      }
      .landingHeader {
        transform: translateX(-100px);
      }
    }
  }
  @media (min-width: 1026px) {
    background-position: center;
    h1 {
      margin: 0;
    }
  }
`

export default Landing
